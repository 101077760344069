$(document).on('click', 'a', function (e) {
  $('[data-toggle=popover]').each(function () {
    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
      $(this).popover('hide');
    }
  });
});

$(document).on('click', '.js-popover-custom', function(e) {
  e.preventDefault();

  let item_id = $(this).data('item-id');
  let item_cl = $(this).data('item-cl');
  let additionParams = $(this).data('addition-params');
  let current_page = "";
  let close_dialog = 'いいえ';
  let submit_dialog = 'はい';
  let title = '本当に削除しますか?';

  if ($(this).hasClass('btn-order-item-destroy')) {
    current_page = 'order-items';
    close_dialog = '閉じる';
  }

  if ($(this).hasClass('btn-order-item-remove')) {
    close_dialog = 'キャンセル';
    submit_dialog = '削除';
    title = '削除しますか?';
  }

  var content = '<div class="text-center js-popover-content" aria-item-id="' + item_id + '" aria-addition-params="' + additionParams + '" aria-item-cl="' + item_cl + '"><span class="btn btn-default js-btn-cancel me-2">' + close_dialog + '</span><span class="btn btn-primary js-btn-ok">' + submit_dialog + '</span></div>';

  if (current_page == 'order-items') {
    title = '注文をキャンセルしますか？';
  }

  $(this).popover({
    html: true,
    title: title,
    trigger: 'click',
    content: content,
    placement: 'bottom',
    template: '<div class="popover popover-custom-area" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
  }).popover('show');
});

$(document).on('click', '.popover-custom-area .js-btn-cancel', function(e) {
  e.preventDefault();

  $(this).parents('.popover').popover('hide');
});

$(document).on('click', '.popover-custom-area .js-btn-ok', function(e) {
  e.preventDefault();

  let id = $(this).parents('.js-popover-content').attr('aria-item-id');
  let cl = $(this).parents('.js-popover-content').attr('aria-item-cl');
  let additionParams = $(this).parents('.js-popover-content').attr('aria-addition-params') || '';

  let current_query = "";
  let url = '/' + cl + '/' + id + additionParams;

  if (location.href.indexOf("?") != -1) {
    current_query = location.href.split("?")[1];
  }

  if (current_query != "") {
    url = url + "?" + current_query;
  }

  $('#js-popover-custom-' + id).popover('hide');

  $.ajax({
    url: url,
    method: 'DELETE',
    data: { 'authenticity_token': $('meta[name="csrf-token"]').attr('content') }
  }).done(function() {
    // $('.card .item-row-' + id).hide('slow');
  });
});
