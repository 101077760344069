import $ from 'jquery'

$(document).on('turbolinks:load', function () {
  $(document.body).on("change", "select#type_of_sheet", function () {
    let type_of_sheet = $(this).val();
    let order_item_id = $(this).attr('data_order_item_id');
    let current_row_checked = $('.item-row-' + order_item_id).find('.js-checkbox-order_item').is(":checked");

    if (current_row_checked) {
      manageLocalStorageOrderItemTypeOfSheet(order_item_id, type_of_sheet, 'update');
    }
  });

  let data_type_of_sheets = getLocalStorageOrderItemTypeOfSheet();
  for (let order_item_id in data_type_of_sheets) {
    var row = $('.item-row-' + order_item_id);
    $(row).find('select#type_of_sheet').val(data_type_of_sheets[order_item_id]);
  }

  let checked_order_item = localStorage.getItem("checked_order_item");
  if (checked_order_item) {
    if (checked_order_item == 'check_all') {
      $('input.js-checked-order_item-ids').val('check_all');

      var checked_order_items = $('input[name=checkbox-order_item]');
      for (let i = 0; i < checked_order_items.length; i++) {
        $(checked_order_items[i]).prop('checked', true);
      }
    } else {
      $('input.js-checked-order_item-ids').val(checked_order_item);

      let checked_order_item_ids = checked_order_item.split(",");
      var checked_order_items = $('input[name=checkbox-order_item]');
      for (let i = 0; i < checked_order_items.length; i++) {
        if (checked_order_item_ids.includes($(checked_order_items[i]).val())) {
          $(checked_order_items[i]).prop('checked', true);
        }
      }
    }
  }

  $('.js-checkbox-order_item').change(function() {
    var is_checked_all = false;

    if(this.checked) {
      if ($(this).val() == "check_all") {
        is_checked_all = true;

        var checked_order_items = $('input[name=checkbox-order_item]');
        for (let i = 0; i < checked_order_items.length; i++) {
          $(checked_order_items[i]).prop('checked', true);
        }
      } else {
        var current_order_item_id = $(this).val();
        var current_type_of_sheet = $('.row.item-row-' + current_order_item_id).find('select#type_of_sheet').val();
        var order_item_ids_storage = localStorage.getItem("checked_order_item");
        var arr_order_item_ids_storage = [];

        manageLocalStorageOrderItemTypeOfSheet(current_order_item_id, current_type_of_sheet, 'update');

        if (order_item_ids_storage != 'check_all') {
          if (order_item_ids_storage) {
            arr_order_item_ids_storage = order_item_ids_storage.split(",");
          }
          arr_order_item_ids_storage.push(current_order_item_id);
          localStorage.setItem("checked_order_item", arr_order_item_ids_storage.join(","));
        }
      }
    } else {
      if ($(this).val() == "check_all") {
        is_checked_all = false;
        localStorage.setItem("checked_order_item", "");

        var checked_order_items = $('input[name=checkbox-order_item]');
        for (let i = 0; i < checked_order_items.length; i++) {
          $(checked_order_items[i]).prop('checked', false);
        }
      } else {
        var current_order_item_id = $(this).val();
        var order_item_ids_storage = localStorage.getItem("checked_order_item");

        manageLocalStorageOrderItemTypeOfSheet(current_order_item_id, '', 'delete');

        if (order_item_ids_storage != 'check_all') {
          var arr_order_item_ids_storage = order_item_ids_storage.split(",");
          let index = arr_order_item_ids_storage.indexOf(current_order_item_id);

          if (index > -1) { // only splice array when item is found
            arr_order_item_ids_storage.splice(index, 1); // 2nd parameter means remove one item only
          }

          localStorage.setItem("checked_order_item", arr_order_item_ids_storage.join(","));
        }
      }
    }

    if (is_checked_all) {
      $('input.js-checked-order_item-ids').val('check_all');
      localStorage.setItem("checked_order_item", "check_all");
    } else {
      var order_item_ids_storage = localStorage.getItem("checked_order_item");

      $('input.js-checked-order_item-ids').val(order_item_ids_storage);
    }
  });
});

$(document).on('click', '.js-btn-export-order_item-print', function (e) {
  e.preventDefault();

  let checked_order_item = localStorage.getItem("checked_order_item");
  let data_type_of_sheets = localStorage.getItem("data_type_of_sheets");

  $.ajax({
    url: '/delivers/print_orders',
    method: 'GET',
    data: {
      order_item_ids: checked_order_item,
      data_type_of_sheets: data_type_of_sheets,
      'authenticity_token': $('meta[name="csrf-token"]').attr('content')
    }
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-export-bill', function (e) {
  e.preventDefault();

  let $this = $(this);
  var bill_ids = '';
  var query_string = '';

  if ($this.hasClass('js-print-all-list-bills-btn')) {
    // export all bills
    bill_ids = 'export_all_bills';
    query_string = location.href;
  } else if ($this.hasClass('js-print-checked-bills-btn')) {
    // export checked bills
    bill_ids = localStorage.getItem("checked_bill");
  } else {
    let bill_id = $this.data('item-id');
    bill_ids = bill_id;
  }

  $this.prop('disabled', true);
  $('.js-printing-notify-modal').modal('show');

  $.ajax({
    url: '/bills/export_pdf',
    method: 'POST',
    data: {
      bill_ids: bill_ids,
      query_string: query_string,
      'authenticity_token': $('meta[name="csrf-token"]').attr('content')
    }
  }).done(function (data) {
    $this.prop('disabled', false);
    $('.js-printing-notify-modal').modal('hide');
    if ($this.hasClass('js-print-checked-bills-btn')) {
      $this.prop('disabled', true);
    }

    if ($this.hasClass('js-print-checked-bills-btn')) {
      localStorage.setItem("checked_bill", "");
      var checked_bills = $('input[name=checkbox-bill]');
      for (let i = 0; i < checked_bills.length; i++) {
        $(checked_bills[i]).prop('checked', false);
      }
    }

    if (data['file_path']) {
      printJS({printable: data['file_path'], type: 'pdf', showModal: true});
    } else {
      alert("全ての店舗に伝票タイプを入力してください。");
    }
  });
});

$(document).on('click', '.js-close-dialog-choose-print-type-btn', function (e) {
  e.preventDefault();

  $('.js-choose-pdf-type-modal').modal('hide');

  if (location.href.indexOf("deliver") <= -1) {
    localStorage.setItem("checked_order_item", "");
    localStorage.setItem("data_type_of_sheets", "");
    location.reload();
  }
});

function getLocalStorageOrderItemTypeOfSheet() {
  var data_type_of_sheets = localStorage.getItem("data_type_of_sheets");
  if (data_type_of_sheets) {
    data_type_of_sheets = JSON.parse(data_type_of_sheets);
  } else {
    data_type_of_sheets = {};
  }

  return(data_type_of_sheets);
};

function manageLocalStorageOrderItemTypeOfSheet(order_item_id, update_type_of_sheet, action) {
  var data_type_of_sheets = getLocalStorageOrderItemTypeOfSheet();

  if (action == 'update') {
    data_type_of_sheets[order_item_id] = update_type_of_sheet
  } else if (action == 'delete') {
    delete(data_type_of_sheets[order_item_id]);
  }

  localStorage.setItem("data_type_of_sheets", JSON.stringify(data_type_of_sheets));
};
